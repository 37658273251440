.fragmentStash .newItemContainer {
  transform: scale(0.641);
  transform-origin: top left;
}

.delveStash .newItemContainer {
  transform: scale(0.897436);
  transform-origin: left top;
}

.essenceStash .newItemContainer {
  transform: scale(0.8154);
  transform-origin: top left;
}

.newItemContainer .stackSize {
  position: absolute;
  top: -1px;
  left: 4px;
  font-weight: bold;
  font-size: 18px;
}

.stash-size-border {
  width: 574px;
  height: 574px;
}

.stash-size {
  width: 569px;
  height: 569px;
}

.default-tab-color-border {
  border-color: rgb(102, 69, 44);
}
