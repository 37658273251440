.newItemContainer.iW2.iH1 .sockets.numSockets1, .newItemContainer.iW2.iH2 .sockets.numSockets1, .newItemContainer.iW2.iH3 .sockets.numSockets1, .newItemContainer.iW2.iH4 .sockets.numSockets1 {
  left: 23.73225px;
  width: 47.4645px;
}

.newItemContainer.iW2.iH2 .sockets.numSockets1, .newItemContainer.iW2.iH2 .sockets.numSockets2 {
  top: 23.73225px;
}

.newItemContainer.iW2.iH3 .sockets.numSockets1, .newItemContainer.iW2.iH3 .sockets.numSockets2 {
  top: 47.4645px;
}

.newItemContainer.iW2.iH3 .sockets.numSockets3, .newItemContainer.iW2.iH3 .sockets.numSockets4 {
  top: 23.73225px;
}

.newItemContainer.iW2.iH4 .sockets.numSockets1, .newItemContainer.iW2.iH4 .sockets.numSockets2 {
  top: 71.19675px;
}

.newItemContainer.iW2.iH4 .sockets.numSockets3, .newItemContainer.iW2.iH4 .sockets.numSockets4 {
  top: 47.4645px;
}

.newItemContainer.iW2.iH4 .sockets.numSockets5, .newItemContainer.iW2.iH4 .sockets.numSockets6 {
  top: 23.73225px;
}

.itemSocket {
  float: left
}
