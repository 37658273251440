.tooltip .tooltipText {
  /* visibility: hidden; */
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 0;
  border-radius: 6px;

  width: 420px;

  /* margin-left: 100px; */
  /* position: absolute; */

  /* min-width: max-content;
  max-width: 600px; */
  background: rgba(0, 0, 0, 0.9);
  /* z-index: 10000; */
  pointer-events: none;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}

.text-blue-magic {
  color: #8888ff;
}

.text-gray-descr {
  color: #7f7f7f;
}

.text-gold-flavour {
  color: #af6025;
}

.tooltip-seperator {
  border: 0;
  height: 2px;
  margin: 0.25rem 10rem;
  min-width: 100px;
  background-image: linear-gradient(
    to right,
    rgba(127, 127, 127, 0),
    rgba(127, 127, 127, 0.75),
    rgba(127, 127, 127, 0)
  );
}
