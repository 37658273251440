.headerLabel {
  position: relative;
  top: 48px;
  left: 0;
  right: 0;
  width: 180px;
  margin: 0 auto;
  line-height: 26px;
  font-family: 'Cinzel';
  font-weight: bold;
  text-align: center;
  color: black;
  /* font-size: 1.5em; */
  font-size: 1em;
  user-select: none;
}

.itemPlaced {
  background: rgba(25, 26, 150, 0.25);
}

.inventoryManagerMenu .characters {
  width: 303px;
  height: 781px;
  cursor: pointer;
  overflow: hidden auto;
}
.inventoryManagerMenu .characters .character {
  position: relative;
  margin: 0px;
  padding: 0px;
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: 0px -100px;
  height: 80px;
  width: 287px;
}
.inventoryManagerMenu .characters .character .infoLine1,
.inventoryManagerMenu .characters .character .infoLine2,
.inventoryManagerMenu .characters .character .infoLine3 {
  left: 83px;
  position: absolute;
}
.inventoryManagerMenu .characters .character .infoLine1,
.inventoryManagerMenu .characters .character .infoLine2 {
  font-size: 14px;
  padding: 3px 9px;
  cursor: pointer;
}
.inventoryManagerMenu .characters .character .infoLine1:hover,
.inventoryManagerMenu .characters .character .infoLine2:hover {
  color: #eccba9;
}
.inventoryManagerMenu .characters .character.expired .infoLine1,
.inventoryManagerMenu .characters .character.expired .infoLine2 {
  color: #757575;
  cursor: auto;
}
.inventoryManagerMenu .characters .character.expired .infoLine1:hover,
.inventoryManagerMenu .characters .character.expired .infoLine2:hover {
  color: #757575;
}
.inventoryManagerMenu .characters .character .infoLine1 {
  top: 15px;
  width: 174px;
}
.inventoryManagerMenu .characters .character .infoLine1 div.expired-character-flag {
  float: left;
  color: red;
  font-size: 20px;
  line-height: 22px;
  padding-right: 2px;
}
.inventoryManagerMenu .characters .character .infoLine1 .characterName {
  display: block;
  max-width: 170px;
  overflow: hidden;
}
.inventoryManagerMenu .characters .character .infoLine2 {
  top: 41px;
}
.inventoryManagerMenu .characters .character .infoLine3 {
  font-size: 110%;
  /* custom font-size */
  font-size: 14px;
  top: 64px;
  padding: 0px 9px;
  color: #757575;
}
.inventoryManagerMenu .characters .character.active .characterName {
  color: #fcdbb9;
  text-shadow: 0px 0px 1px #e7dcc8;
}
.inventoryManagerMenu .characters .icon {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.inventoryManagerMenu .characters .icon.Marauder {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -658px -80px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Juggernaut {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -400px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Berserker {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -480px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Chieftain {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -160px -580px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Shadow {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -658px -160px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Assassin {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: 0px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Saboteur {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -80px -580px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Trickster {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -240px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Witch {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -330px -340px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Occultist {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: 0px -580px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Elementalist {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -560px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Necromancer {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -658px -320px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Templar {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -320px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Inquisitor {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -160px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Hierophant {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -440px -420px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Guardian {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -220px -180px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Duelist {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -658px -480px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Slayer {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -658px 0px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Gladiator {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -80px -500px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Champion {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -658px -240px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Ranger {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -320px -580px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Deadeye {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -400px -580px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Raider {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -480px -580px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Pathfinder {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -240px -580px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Scion {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -658px -400px;
  width: 80px;
  height: 80px;
}
.inventoryManagerMenu .characters .icon.Ascendant {
  background: url('https://web.poecdn.com/image/gen/inventory-sprite.png') no-repeat;
  background-position: -330px -260px;
  width: 80px;
  height: 80px;
}
