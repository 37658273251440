.stash-container {
  display: flex;
}

.stash-grid {
  border: 1px ridge grey;
  display: flex;
  flex-wrap: wrap;
  background-size: 50px 50px;
  background-image: linear-gradient(to right, grey 1px, transparent 1px), linear-gradient(to bottom, grey 1px, transparent 1px);
}

.stash-grid-y {
  /* outline: 0.5px ridge grey; */
  height: 50px;
  width: 50px;
}

.stash-item-image-container .tooltiptext {
  visibility: hidden;
  background: rgba(0,0,0,0.85);
  max-width: 600px;
  /* color: #fff; */
  text-align: left;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  /* top: -5px;
  left: -5px; */
  margin-left: 100px;
  position: absolute;
  z-index: 1;
}

.stash-item-image {
  background-color: rgba(25,26,150,0.25);
}

.stash-item-image.priced {
  background-color: gold;
}

.stash-item-image.underpriced {
  background-color: #6b8e23;
}

.stash-item-image.overpriced {
  background-color: #8C0000;
}

.stash-item-image:hover {
  background-color: #463E1D;
}

.stash-item-image-container:hover .tooltiptext {
  visibility: visible;
}

.corruped-line {
  color: red;
}

.label-enchant {
  background-color: #8B579C;
}

.itemframetype-0 {
  color: #eee;
}

.itemframetype-1 {
  color: #5050EB;
}

.itemframetype-2 {
  color: #a3a314;
}

.itemframetype-3 {
  color: #af6025;
}

.itemframetype-4 {
  color: #1ba29b;
}

.itemframetype-5 {
  color: #9c7e38;
}

.itemframetype-9 {
  color: #82ad6a
}

.itemPlaced {
  background: rgba(25, 26, 150, 0.25);
}

.itemPlacedSelected {
  background: rgba(60, 78, 36, 0.50);
}
